import loadjs from "loadjs";
import react from "react";
import reactDOM from "react-dom";
import * as reactRouterDOM from "react-router-dom";
import * as podiumUiServicesWeb from "@podium-ui/services-web";
import { AuthService } from "@podium-ui/services-web";
import * as MaterialUI from "@material-ui/core";
import {
  podiumUIdependencies,
  podiumUIDataDependencies,
  podiumUISharedDependenciess,
} from "dependancies";

(window as any).React = react;
(window as any).ReactDOM = reactDOM;
(window as any).ReactRouterDOM = reactRouterDOM;
(window as any).PodiumUiServicesWeb = podiumUiServicesWeb;
(window as any).MaterialUI = MaterialUI;

const loadApp = () => {
  loadjs(
    podiumUISharedDependenciess.map(
      (dep) => `${process.env.REACT_APP_PODIUMUI_URL}/${dep}.js`
    ),
    "podium-shared-web"
  );

  loadjs.ready("podium-shared-web", function () {
    loadjs(
      podiumUIdependencies.map(
        (dep) => `${process.env.REACT_APP_PODIUMUI_URL}/${dep}.js`
      ),
      "podium-newui"
    );
  });

  loadjs.ready("podium-newui", function () {
    loadjs([`${process.env.REACT_APP_PODIUMUI_URL}/fonts.css`], "podiumui");
  });

  loadjs.ready("podiumui", function () {
    import("./bootstrap");
  });
};
//@ts-ignore
AuthService.initKeycloak(loadApp);
